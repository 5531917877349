@import 'styles/mixins';
@import 'styles/variables';

.section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 1332px;
  padding: 32px 16px;
  margin: 0 auto;

  @include media('>=screen-md') {
    padding: 64px 16px;
  }
}

.sectionMargin {
  margin-top: 24px;
}

.sectionTitle {
  font-size: 24px;
  font-weight: bold;
  color: $gray-800;
  margin-top: 20px;
  margin-bottom: 10px;

  @include media('>=screen-sm') {
    font-size: 30px;
  }
  @include media('>=screen-md') {
    font-size: 36px;
  }
  @include media('>=screen-lg-shop') {
    font-size: 40px;
  }
}

.sectionTitleSmall {
  font-size: 20px;
  font-weight: bold;
  color: $gray-800;
  margin-top: 20px;
  margin-bottom: 10px;

  @include media('>=screen-sm') {
    font-size: 22px;
  }
  @include media('>=screen-md') {
    font-size: 26px;
  }
  @include media('>=screen-lg-shop') {
    font-size: 30px;
  }
}

.sectionDescription {
  font-size: 16px;
  color: $gray-800;
  @include media('>=screen-sm') {
    font-size: 18px;
  }
  @include media('>=screen-md') {
    font-size: 20px;
  }
  @include media('>=screen-lg-shop') {
    font-size: 24px;
  }
}

.sliderWrapper {
  width: 100%;
  padding: 15px 0;

  @include media('>=screen-sm') {
    padding: 24px 16px
  }
}

.link {
  font-weight: bold;
  color: $blue-700;
  font-size: 16px;
  transition: color 0.3s linear;

  @include media('>=screen-sm') {
    font-size: 18px;
  }
  @include media('>=screen-md') {
    font-size: 20px;
  }
  @include media('>=screen-lg-shop') {
    font-size: 24px;
  }
  &:hover {
    text-decoration: underline;
    color: $blue-900;
  }
}

.rightArrowIcon {
  font-size: 50px;
  vertical-align: middle;
  line-height: 21px;
  display: inline-block;
}
